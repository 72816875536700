import React from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ParagraphsHolder from '../components/paragraphs-holder';

const BlogPost: React.FC<PageProps<DataProps>> = ({ data, path, pageContext }) => {
    const post = data.datoCmsArticle;
    const localeDateConf: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };
    const localeTimeConf: Intl.DateTimeFormatOptions = {
        minute: '2-digit',
        hour: '2-digit',
    }

    return (
        <Layout title={post.title}>
            <SEO title={post.title} />

            <h1 className='mb-1 text-2xl text-center md:text-3xl'>{post.title}</h1>
            <p className='mb-6 text-center text-gray-400'>Erstellt am: {new Date(post.meta.firstPublishedAt).toLocaleDateString('de', localeDateConf)} um {new Date(post.meta.firstPublishedAt).toLocaleTimeString('de', localeTimeConf)} Uhr</p>
            <ParagraphsHolder paragraphs={post.paragraphs}></ParagraphsHolder>
        </Layout>
    )
}

export default BlogPost;

export const query = graphql`
query SingleBlogPost($id: String) {
    datoCmsArticle(id: { eq: $id }) {
        id
        title
        meta {
            firstPublishedAt
        }
        paragraphs {
            ... on DatoCmsText {
                id
                model {
                    apiKey
                }
                textNode {
                    childMarkdownRemark {
                        html
                    }
                }
            }
            ... on DatoCmsImage {
                id
                model {
                    apiKey
                }
                image {
                    fluid(maxWidth: 728) {
                        ...GatsbyDatoCmsFluid
                    }
                }
            }
            ... on DatoCmsSpotifyPlaylist {
                title
                playlistId
                id
                model {
                    apiKey
                }
                descriptionNode {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }
}
`
